module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#242F39","display":"minimal-ui","icon":"./src/images/megaphone.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5YbzNxSHhBQUFDTUF3ckQt.77-977-977-977-9ATXvv719IjdXaO-_vV9sfe-_ve-_ve-_ve-_vXDvv71IWDViT0IWZe-_vTg","repositoryName":"madeby","defaultLang":"de","langs":["de","en-gb"],"shortenUrlLangs":true,"path":"/preview","previews":true,"pages":[{"type":"Post","match":"/post/:uid","path":"/posts","component":"/__w/madeby.tickaroo.com/madeby.tickaroo.com/src/templates/post.js","langs":["de-de"]},{"type":"Post","match":"/:lang?/post/:uid","path":"/posts","component":"/__w/madeby.tickaroo.com/madeby.tickaroo.com/src/templates/post.js","langs":["en-gb"]},{"type":"Showcaseitem","match":"/showcase-slide/:uid","path":"/showcases","component":"/__w/madeby.tickaroo.com/madeby.tickaroo.com/src/templates/showcaseItem.js","sortBy":"sort_ASC","langs":["de-de"]},{"type":"Showcaseitem","match":"/:lang?/showcase-slide/:uid","path":"/showcases","component":"/__w/madeby.tickaroo.com/madeby.tickaroo.com/src/templates/showcaseItem.js","sortBy":"sort_ASC","langs":["en-gb"]}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KCF8HDP","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"scope":"madeby"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
