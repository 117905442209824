
export const linkResolver = (doc) => {
  let url = '/';

  // FIXME : this is not dynamic, only works for our two languages for now
  if (doc.lang !== 'de-de') {
    url += 'en/'
  }

  if (doc.type === 'post') {
    url += 'post/'
    url += doc.uid

    return url;
  } else if (doc.type === 'showcaseitem') {
    url += 'showcase-slide/'
    url += doc.uid

    return url;
  }

  // for unknown types
  return '/';
};



const isDefault = (lang) => {
  return lang.startsWith('de')
}

const langFragment = (lang) => {
  if (isDefault(lang)) {
    return '/'
  } else {
    return forcedLangFragment(lang)
  }
}

// we need this for templates, as the don't have the fallback url
const forcedLangFragment = (lang) => {
  const langForUrl = lang.split('-')[0]
  
  return `/${langForUrl}/`
}


export const rootPath = ({lang}) => {
  return langFragment(lang)
}
export const rootServicesPath = ({lang}) => {
  return rootPath({lang: lang}) + '#services'
}
export const rootShowcasesPath = ({lang}) => {
  return rootPath({lang: lang}) + '#showcases'
}
export const rootCTAPath = ({lang}) => {
  return rootPath({lang: lang}) + '#cta'
}

export const blogPath = ({lang}) => {
  return langFragment(lang) + `blog`
}

export const blogPostPath = (doc) => {
  return langFragment(doc.lang) + `post/${doc.uid}`
}

export const showcaseItemPath = (doc) => {
  return langFragment(doc.lang) + `showcase-slide/${doc.uid}`
}

export const showcaseItemUidPath = (uid, {lang}) => {
  return langFragment(lang) + `showcase-slide/${uid}`
}


// WARNING: the logic here depends on the url structure
//          any changes to the url structure might need update here
export const switchLanguagePath = (location, {lang}) => {
  console.log('switchLanguagePath', location)
  const {pathname, hash} = location
  
  // can't link to switched language in same article so back to top
  if (pathname.startsWith('/post') || pathname.startsWith('/en/post')) {
    return blogPath({lang})
  }

  // en and de have same uids, so we can directly switch language
  if (pathname.startsWith('/showcase-slide') || pathname.startsWith('/en/showcase-slide')) {
    const uid = pathname.split('/').slice(-1)[0]
    return showcaseItemUidPath(uid, {lang})
  }

  // default behavior, works for all anchors, regular pages and even 404 page
  const pathWithoutLang = pathname.replace('en/', '')
  return langFragment(lang) + pathWithoutLang + hash;
}